@use '@angular/material' as mat;
@import 'theme.scss';

body {
  padding:0; margin:0;
  color: mat.get-color-from-palette($primary, 900);
}

app-root {
  height: 100vh;
}

mat-toolbar span.spacer {
  flex: 1 1 auto;
}

.success-snackbar.mat-mdc-snack-bar-container .mat-mdc-snack-bar-label {
  color: black;
  background-color: #D0F0C0;
}

.success-snackbar.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #D0F0C0;
}

.error-snackbar.mat-mdc-snack-bar-container .mat-mdc-snack-bar-label {
  color: #8B0000;
  background-color: #ffcccb;
}

.error-snackbar.mat-mdc-snack-bar-container .mdc-snackbar__surface {
  background-color: #ffcccb;
}

h1.mat-mdc-dialog-title.mdc-dialog__title {
  border-bottom: 1px solid rgba(0, 0, 0, .12);
  margin: 0px 5px;
  padding: 0px 10px;
}

.mat-mdc-dialog-container .mat-mdc-dialog-actions {
  /* padding: var(--mat-dialog-actions-padding, 8px); */
  justify-content: end;
}